import * as THREE from "three";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
// import * as dat from "lil-gui";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
// import rhymeDict from "./dictionaries/rhyme-with-us-dict/rhyme-with-us-dict.json" assert { type: "json" };
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, get, onValue, child } from "firebase/database";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { animals } from "./animal-names.js";
import { Howl } from "howler";

//===================================
/////////////////////////////////////
//////////// Loading ////////////////
/////////////////////////////////////
/////////////////////////////////////
globalThis.timerIsLoaded = false;
globalThis.usernameIsLoaded = false;
globalThis.isLoaded = false;
globalThis.isReadyToPlay = false;
function checkIfLoaded() {
  // console.log("check if loaded");
  if (
    globalThis.timerIsLoaded === true &&
    globalThis.usernameIsLoaded === true
  ) {
    globalThis.isLoaded = true;
    const loadingScreenEarth = document.querySelector("#loadingScreenEarth");
    loadingScreenEarth.textContent = "";
    const startButton = document.createElement("div");
    startButton.className = "content";
    const textShadows = document.createElement("div");
    textShadows.className = "text_shadows";
    textShadows.textContent = "Start";
    startButton.appendChild(textShadows);
    loadingScreenEarth.appendChild(startButton);

    // <div id="startButton" class="content">
    // <h2 class="text_shadows">hey</h2>
    // </div>;

    if (globalThis.isReadyToPlay === true) {
      console.log("RETrweqergt");
      const introBlocker = document.querySelector("#introBlocker");
      introBlocker.style.visibility = "hidden";
      introBlocker.style.display = "none";
    }
  }
}
globalThis.handleClickStart = () => {
  console.log("Start Clicked");
  globalThis.isReadyToPlay = true;
  checkIfLoaded();
};
//===================================
/////////////////////////////////////
////////////// Sound ////////////////
/////////////////////////////////////
/////////////////////////////////////

// function playPlayingMusic() {
//   // iterate
//   // fade current music out
//   // fade in new music//
//   stopMusic();
//   var playingMusic = new Howl({
//     src: ["/sounds/music/Playing2.mp3"],
//     loop: true,
//     html5: false,
//     volume: 0.3,
//     mobileAutoEnable: false,
//   });

//   globalThis.music = playingMusic;
//   playMusic();
// }

function playMusic() {
  console.log("Play Music");
  if (!!globalThis.music) {
    if (globalThis.musicEnabled === true) {
      console.log("play globalThis.music");
      console.log(globalThis.music);
      globalThis.currentMusicId = globalThis.music.play();
      globalThis.music.fade(0, 1, 1000, globalThis.currentMusicId);
    }
  }
}
function stopMusic() {
  console.log("Stop Music");
  if (!!globalThis.music) {
    globalThis.music.fade(1, 0, 1000, globalThis.currentMusicId);
    globalThis.music.stop();
  }
}

// function playLobbyMusic() {
//   // iterate
//   // fade current music out
//   // fade in new music
//   stopMusic();
//   var lobbyMusic = new Howl({
//     src: ["/sounds/music/Lobby2.mp3"],
//     // autoplay: true,
//     loop: true,
//     html5: false,
//     volume: 0.3,
//   });

//   globalThis.music = lobbyMusic;
//   playMusic();
// }
// function setMusic() {
//   // var music = new Howl({
//   //   src: ["/sounds/music/Lobby1.mp3"],
//   //   // autoplay: true,
//   //   loop: true,
//   //   html5: false,
//   //   volume: 0.3,
//   // });
//   if (typeof globalThis.playingMusic === "undefined") {

//   }
//   // globalThis.music = music;
// }
function playGameMusic() {
  // iterate
  // fade current music out
  // fade in new music//
  stopMusic();
  const maxTracks = 2;
  setGameMusic();
  function setGameMusic() {
    console.log("Set Game Music");
    if (!globalThis.gameMusicIndex) {
      globalThis.gameMusicIndex = 0;
    }
    globalThis.gameMusicIndex++;
    if (globalThis.gameMusicIndex > maxTracks) {
      globalThis.gameMusicIndex = 1;
    }
    globalThis.music = new Howl({
      src: [`/sounds/music/track${globalThis.gameMusicIndex}.webm`],
      // src: [`/sounds/music/track${2}.mp3`],
      loop: true,
      autoplay: true,
      html5: true,
      volume: 0.3,
      mobileAutoEnable: true,
    }).on("end", function () {
      setGameMusic();
    });
  }

  playMusic();
}
function handleToggleSound() {
  console.log("toggleSound");
  const soundButton = document.querySelector("#soundButton");
  globalThis.musicEnabled = !globalThis.musicEnabled;
  if (globalThis.musicEnabled === true) {
    playGameMusic();
    if (!!globalThis.music) {
      // globalThis.music.play();
      soundButton.textContent = "🔊";
    }
  } else {
    if (!!globalThis.music) {
      globalThis.music.pause();
    }
    soundButton.textContent = "🔇";
  }
}
// setMusic()
globalThis.handleToggleSound = handleToggleSound;
globalThis.playingMusicIndex = 0;
globalThis.lobbyMusicIndex = 0;
globalThis.musicEnabled = false;
globalThis.music = undefined;
//===================================
/////////////////////////////////////
/////////// CONSTANTS ///////////////
/////////////////////////////////////
/////////////////////////////////////

// globalThis.myLevel = 1;
const serverUid = "lfacNX3WXSPv9eU5zrLShg38IVB3";

if (localStorage.myUsername) {
  globalThis.myUsername = localStorage.myUsername;
} else {
  globalThis.myUsername = `Guest${getRandomAnimal()}${getRandomInt(
    1000,
    9999
  )}`;
  localStorage.myUsername = globalThis.myUsername;
}
// console.log(globalThis.myUsername);

const log = console.log;
/////////////////////////////////////
/////////////////////////////////////
////////// END CONSTANTS ////////////
/////////////////////////////////////
/////////////////////////////////////

//===================================
/////////////////////////////////////
///////// INITIALIZATION ////////////
/////////////////////////////////////
/////////////////////////////////////

/////////////////////////////////////
////////////// END //////////////////
///////// INITIALIZATION ////////////
/////////////////////////////////////
/////////////////////////////////////

globalThis.handleUsernameClicked = () => {
  // usernameLabelSt
  // hideLobbyLeaderboard();
  // hideCreateAccountLoginButtonRo
  hideMenus();
  showPlayerProfileContainer();
};

function setUserNameLabel() {
  const userNameLabel = document.querySelector("#userNameLabel");
  userNameLabel.textContent = `Level ${globalThis.myLevel} ${globalThis.myUsername}`;
  globalThis.usernameIsLoaded = true;
}
function getRandomAnimal() {
  const _animals = animals();
  const animalIndex = getRandomInt(0, _animals.length - 1);
  return _animals[animalIndex];
}

// function logUserIn(email, password) {
//   const auth = getAuth();
//   signInWithEmailAndPassword(auth, email, password)
//     .then((userCredential) => {
//       // Signed in
//       const user = userCredential.user;
//       // ...
//       console.log("user logged in");
//       console.log(user);
//     })
//     .catch((error) => {
//       const errorCode = error.code;
//       const errorMessage = error.message;
//     });
// }
// todo: call this when round state changes to Lobby
function setXpBar(params) {
  console.log("Set Xp Bar");
  // console.log(params);
  const newXp = params.newXp;
  const myXp = params.myXp;

  // calculate myLevel from /my-xp before setXpBar is called
  const expectedXpPerLevel = 100;
  const myLevel =
    Math.floor(Math.pow(myXp / expectedXpPerLevel, 1 / 1.075)) + 1;

  const myNewLevel = Math.floor(
    Math.pow(newXp / expectedXpPerLevel, 1 / 1.075) + 1
  );

  globalThis.myLevel = myLevel;
  setUserNameLabel();

  console.log(`xp: ${myXp} -> ${newXp}`);
  console.log(`level: ${myLevel} -> ${myNewLevel}`);

  const levelsToDing = myNewLevel - myLevel;
  log("levelsToDing");
  log(levelsToDing);
  const xpMax = (myNewLevel * expectedXpPerLevel) ** 1.075;
  const xpBar = document.querySelector("#xpBar");
  const xpWrapper = document.querySelector("#xpWrapper");
  const xpBackground = document.querySelector("#xpBackground");

  xpBackground.textContent = `/${addCommas(xpMax)}\u00A0`;

  let initialXpPercentage = parseFloat(((myXp / xpMax) * 100).toFixed(0));
  console.log("initialXpPercentage");
  console.log(initialXpPercentage);
  xpBar.style.width = `${initialXpPercentage}%`;
  xpBar.textContent = `xp: ${addCommas(myXp)}\u00A0`;
  for (var i = 0; i < levelsToDing; i++) {
    doDings(i);
  }

  function doDings(j) {
    setTimeout(function () {
      //  tasks to do;
      replayXpBarAnimation(j, levelsToDing);

      globalThis.myLevel = myLevel + j;
      setUserNameLabel();
      log("ding! " + j);
    }, 4000 * j);
  }
  // var i = 1; //  set your counter to 1

  // for (let i = 0; i < levelsToDing; i++) {
  //   console.log("Ding!");
  //   if (i === levelsToDing - 1) {
  //     // send the bar from 0 to the percentage complete
  //     const initialXpPercentage = 0;
  //     const newXpPercentage = parseFloat(((newXp / xpMax) * 100).toFixed(0));
  //     replayXpBarAnimation(initialXpPercentage, newXpPercentage);
  //   } else {
  //     // send the bar from 0 to 100
  //     const initialXpPercentage = 0;
  //     const newXpPercentage = 100;
  //     replayXpBarAnimation(initialXpPercentage, newXpPercentage);
  //   }
  // }

  function addCommas(number) {
    return parseFloat(number.toFixed(0)).toLocaleString("en-US");
  }

  const customAnimations = document.createElement("style");
  function getKeyFrames(_initialXpPercentage, _newXpPercentage) {
    console.log("getKeyFrames _initialXpPercentage");
    console.log(_initialXpPercentage);
    console.log(" getKeyFrames _newXpPercentage");
    console.log(_newXpPercentage);
    return `
  @keyframes growXp {
    0% {
      width: ${0}%;
    }
    50% {
      width: ${
        _initialXpPercentage + (_newXpPercentage - _initialXpPercentage) / 4
      }%;
    }
    100% {
      width: ${_newXpPercentage}%;
    }
  }
  `;
  }

  document.getElementsByTagName("head")[0].appendChild(customAnimations);
  function replayXpBarAnimation(currentDing, levelsToDing) {
    console.log("replayXpBarAnimation newXp");
    console.log(newXp);
    console.log("replayXpBarAnimation xpMax");
    console.log(xpMax);
    xpBar.style.animation = "none";
    const newXpPercentage = parseFloat(((newXp / xpMax) * 100).toFixed(0));
    if (currentDing === levelsToDing - 1) {
      customAnimations.innerHTML = getKeyFrames(0, newXpPercentage);
    } else {
      customAnimations.innerHTML = getKeyFrames(0, 100);
    }
    xpBar.style.animation = "growXp 4s linear forwards";
    var intervalId = setInterval(function () {
      const completionPercentage = xpBar.offsetWidth / xpWrapper.offsetWidth;
      const completionXp = addCommas(completionPercentage * xpMax);
      xpBar.textContent = `${completionXp}\u00A0`;
      // log("completionPercentage");
      // log(completionPercentage);
      if (completionPercentage > 0.2) {
        xpBar.textContent = `xp: ${xpBar.textContent}`;
      }
    }, 10);
    function setFinalXpValue(newXp) {
      console.log("Final");
      xpBar.textContent = `xp: ${addCommas(
        (newXpPercentage / 100) * xpMax
      )}\u00A0`;
      globalThis.myXp = newXp;
    }
    setTimeout(clearInterval, 4000, intervalId);
    setTimeout(setFinalXpValue, 4000, newXp);
    document.getAnimations().forEach((anim) => {
      if (anim.animationName == "growXp") {
        anim.cancel();
        anim.play();
      }
    });
  }
}

// function setXpBar(params) {
//   const newXp = params.newXp;
//   const myXp = globalThis.myXp;
//   // calculate myLevel from /my-xp before setXpBar is called
//   const expectedXpPerLevel = 100;
//   const myLevel = Math.floor(Math.pow(myXp / expectedXpPerLevel, 1 / 1.075));
//   globalThis.myLevel = myLevel;
//   setUserNameLabel();

//   const xpMax = (myLevel * expectedXpPerLevel) ** 1.075;
//   const xpBar = document.querySelector("#xpBar");
//   const xpWrapper = document.querySelector("#xpWrapper");
//   const xpBackground = document.querySelector("#xpBackground");

//   // calculate initialXpPercentage based on currentXp and XpMax
//   let initialXpPercentage = parseFloat(((myXp / xpMax) * 100).toFixed(0));
//   console.log("initialXpPercentage");
//   console.log(initialXpPercentage);
//   xpBar.style.width = `${initialXpPercentage}%`;
//   console.log("xpBar");
//   console.log(xpBar);
//   // calculate newXpPercentage based on my currentXp + new Xp and XpMax
//   let newXpPercentage = parseFloat(((newXp / xpMax) * 100).toFixed(0));

//   // if newXpPercentage is greater than 100 then run the loop to max, subtract 100, then recurse
//   // set timeout in between runs
//   function addCommas(number) {
//     return parseFloat(number.toFixed(0)).toLocaleString("en-US");
//   }

//   xpBackground.textContent = `/${addCommas(xpMax)}\u00A0`;

//   const customAnimations = document.createElement("style");
//   function getKeyFrames(_initialXpPercentage, _newXpPercentage) {
//     console.log("_initialXpPercentage");
//     console.log(_initialXpPercentage);
//     return `
//   @keyframes growXp {
//     0% {
//       width: ${0}%;
//     }
//     50% {
//       width: ${
//         _initialXpPercentage + (_newXpPercentage - _initialXpPercentage) / 4
//       }%;
//     }
//     100% {
//       width: ${_newXpPercentage}%;
//     }
//   }
//   `;
//   }

//   document.getElementsByTagName("head")[0].appendChild(customAnimations);

//   // Run this as many times as necessary
//   function replayXpBarAnimation(newXpPercentage) {
//     console.log("initialXpPercentage");
//     console.log(initialXpPercentage);
//     console.log("Replay Xp Bar");
//     console.log(newXpPercentage);
//     xpBar.style.animation = "none";
//     customAnimations.innerHTML = getKeyFrames(
//       initialXpPercentage,
//       newXpPercentage
//     );
//     console.log("getKeyFrames()");
//     console.log(getKeyFrames(initialXpPercentage, newXpPercentage));
//     xpBar.style.animation = "growXp 4s linear forwards";
//     var intervalId = setInterval(function () {
//       const completionPercentage = xpBar.offsetWidth / xpWrapper.offsetWidth;
//       const completionXp = addCommas(completionPercentage * xpMax);
//       xpBar.textContent = `${completionXp}\u00A0`;
//       // log("completionPercentage");
//       // log(completionPercentage);
//       if (completionPercentage > 0.2) {
//         xpBar.textContent = `xp ${xpBar.textContent}`;
//       }
//     }, 10);
//     function setFinalXpValue(newXp) {
//       xpBar.textContent = `xp ${addCommas(
//         (newXpPercentage / 100) * xpMax
//       )}\u00A0`;
//       globalThis.myXp = newXp;
//     }
//     setTimeout(clearInterval, 4000, intervalId);
//     setTimeout(setFinalXpValue, 4000, newXp);
//   }
//   let remainingNewXpPercentage = newXpPercentage;

//   function runXpAnimationForEachDing() {
//     console.log("runXpAnimationForEachDing!");

//     console.log("newXpPercentage");
//     console.log(newXpPercentage);
//     if (remainingNewXpPercentage > 100) {
//       replayXpBarAnimation(100);
//       initialXpPercentage = 0;
//       newXpPercentage = 100;
//       remainingNewXpPercentage -= 100;
//       console.log("remainingNewXpPercentage1");
//       console.log(remainingNewXpPercentage);
//       setTimeout(runXpAnimationForEachDing, 4000);
//     } else {
//       console.log("send the rest!");
//       console.log("remainingNewXpPercentage2");
//       console.log(remainingNewXpPercentage);
//       newXpPercentage = remainingNewXpPercentage;
//       // initialXpPercentage = 0;
//       console.log("remainingNewXpPercentage");
//       console.log(remainingNewXpPercentage);
//       replayXpBarAnimation(newXpPercentage);
//     }
//   }
//   runXpAnimationForEachDing();

//   // replayXpBarAnimation();
// }

globalThis.handleLoginSubmit = handleLoginSubmit;

function handleLoginSubmit() {
  console.log("handleLoginSubmit");

  const errorMessage = document.querySelector("#errorMessage");
  errorMessage.textContent = "";

  const emailInput = document.querySelector("#loginEmailInput");
  // const usernameInput = document.querySelector("#usernameInput");
  const passwordInput = document.querySelector("#loginPasswordInput");
  // const password2Input = document.querySelector("#password2Input");

  if (emailInput.value.length < 3) {
    errorMessage.textContent = "Email Required";
    return;
  }
  // if (usernameInput.value.length < 2) {
  //   errorMessage.textContent = "Username Required";
  //   return;
  // }
  if (passwordInput.value.length < 2) {
    errorMessage.textContent = "Password Required";
    return;
  }
  // console.log("password2Input.value.length");
  // console.log(password2Input.value.length);
  // if (password2Input.value.length < 2) {
  //   errorMessage.textContent = "Password2 Required";
  //   return;
  // }
  // if (passwordInput.value != password2Input.value) {
  //   errorMessage.textContent = "Passwords Don't Match";
  //   return;
  // }
  const auth = getAuth();
  signInWithEmailAndPassword(auth, emailInput.value, passwordInput.value)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log("user");
      console.log(user);

      // This user is logged in!
      // Set global state and assign a username
      // Also, disable the stuck in lobby feature
      globalThis.stayInLobby = true;
      hideLoginFormList();
      showLobbyLeaderboardList();
      hideCreateAccountLoginButtonRow();
      getMyUsername(user.uid);
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorMessage);
      errorMessage.textContent = error.message;
      // ..
    });
}

globalThis.handleCreateAccountSubmit = handleCreateAccountSubmit;

function handleCreateAccountSubmit() {
  console.log("handleCreateAccountSubmit");

  const errorMessage = document.querySelector("#errorMessage");
  errorMessage.textContent = "";

  const emailInput = document.querySelector("#emailInput");
  const usernameInput = document.querySelector("#usernameInput");
  const passwordInput = document.querySelector("#passwordInput");
  const password2Input = document.querySelector("#password2Input");

  if (emailInput.value.length < 3) {
    errorMessage.textContent = "Email Required";
    return;
  }
  if (usernameInput.value.length < 2) {
    errorMessage.textContent = "Username Required";
    return;
  }
  if (passwordInput.value.length < 2) {
    errorMessage.textContent = "Password Required";
    return;
  }
  console.log("password2Input.value.length");
  console.log(password2Input.value.length);
  if (password2Input.value.length < 2) {
    errorMessage.textContent = "Password2 Required";
    return;
  }
  if (passwordInput.value != password2Input.value) {
    errorMessage.textContent = "Passwords Don't Match";
    return;
  }
  const auth = getAuth();
  createUserWithEmailAndPassword(auth, emailInput.value, password2Input.value)
    .then(async (userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log("user");
      console.log(user);
      // This user is logged in!
      // Set global state and assign a username
      const username = usernameInput.value;
      await saveUsername(user, username);
      globalThis.stayInLobby = false;
      hideCreateUserFormList();
      showLobbyLeaderboardList();
      getMyUsername(user.uid);
      globalThis.myUsername = username;
      hideCreateAccountLoginButtonRow();
      // globalThis.myUid = user.uid;
      function saveUsername(firebaseUser, username) {
        return set(ref(db, `/my-username/${firebaseUser.uid}`), username);
      }
    })
    .catch((error) => {
      const errorCode = error.code;

      errorMessage.textContent = error.message;

      // ..
    });
}
// Handle Leaderboard and Dictionary Tab Buttons
globalThis.handleClickLeaderboard = handleClickLeaderboard;
globalThis.handleClickDefinitions = handleClickDefinitions;
globalThis.handleCreateAccountClicked = handleCreateAccountClicked;
globalThis.handleLogInClicked = handleLogInClicked;
function handleCreateAccountClicked() {
  // hideLoginFormList();
  console.log("handleCreateAccountClicked");
  // hideLobbyDefinitionsList();
  // hideLobbyLeaderboardList();
  hideMenus();
  showPlayerProfileContainer();
  showCreateUserFormList();
  const createAccountButton = document.querySelector("#createAccountButton");
  createAccountButton.style.color = "white";
}
function hideMenus() {
  hideLoginFormList();
  hideNextDifficulty();
  // hideLobbyLeaderboard();
  hideLobbyLeaderboardList();
  hideLobbyDefinitionsList();
  hideCreateUserFormList();
  hidePlayerProfileContainer();
}
function handleLogInClicked() {
  // showLoginFormList();
  // hideLobbyDefinitionsList();
  // hideLobbyLeaderboardList();
  // hideCreateUserFormList();
  hideMenus();
  showPlayerProfileContainer();
  console.log("handleLogInClicked");
  const loginButton = document.querySelector("#loginButton");
  loginButton.style.color = "white";
  const loginFormList = document.querySelector("#logInFormList");
  loginFormList.style.display = "block";
}
globalThis.stayInLobby = false;

function populateLeaderboard(leaderboardEntries) {
  const lobbyLeaderboardListItems = document.querySelector(
    "#lobbyLeaderboardListItems"
  );
  // console.log(`populate leaderboard!`);
  // console.log(leaderboardEntries);
  if (leaderboardEntries) {
    lobbyLeaderboardListItems.textContent = "";
    // console.log(leaderboardEntries);
    leaderboardEntries.forEach((leaderboardEntry, index) => {
      const leaderboardUsernameDiv = document.createElement("div");
      // (index == 0 ?? "👑", index)
      let crown = "";
      if (index === 0) {
        crown = "👑";
      }
      leaderboardUsernameDiv.textContent = `${index + 1}. ${crown} ${
        leaderboardEntry.username
      }`;
      if (index === 0) {
        leaderboardUsernameDiv.textContent = `${leaderboardUsernameDiv.textContent}`;
      }
      const leaderboardScoreDiv = document.createElement("div");
      leaderboardScoreDiv.textContent = leaderboardEntry.score;
      leaderboardScoreDiv.className = "lobbyLeaderboardScoreListItem";
      lobbyLeaderboardListItems.appendChild(leaderboardUsernameDiv);
      lobbyLeaderboardListItems.appendChild(leaderboardScoreDiv);
    });
  }
  log("globalThis.myRank");
  log(globalThis.myRank);
  if (!!globalThis.myRank) {
    const myRank = Object.values(globalThis.myRank)[0];
    if (myRank > 5) {
      const myRankContainer = document.createElement("span");
      myRankContainer.style.width = "100%";
      if (myRank != 6) {
        const myRankElipsisDiv = document.createElement("div");
        myRankElipsisDiv.textContent = "...";
        lobbyLeaderboardListItems.appendChild(myRankElipsisDiv);
        const myRankElipsisScoreDiv = document.createElement("div");
        myRankElipsisScoreDiv.textContent = "...";
        myRankElipsisScoreDiv.className = "lobbyLeaderboardScoreListItem";
        lobbyLeaderboardListItems.appendChild(myRankElipsisScoreDiv);
      }
      console.log("132345?");
      const myRankNameDiv = document.createElement("div");
      const myRankScoreDiv = document.createElement("div");
      myRankScoreDiv.className = "lobbyLeaderboardScoreListItem";
      myRankNameDiv.textContent = `${Object.values(globalThis.myRank)[0]}. ${
        globalThis.myUsername
      }`;
      myRankScoreDiv.textContent = `${-1 * Object.keys(globalThis.myRank)[0]}`;
      myRankContainer.textContent = "";
      myRankContainer.appendChild(myRankNameDiv);
      lobbyLeaderboardListItems.appendChild(myRankContainer);
      lobbyLeaderboardListItems.appendChild(myRankScoreDiv);
    }
  }

  // <div>1. Marvin</div>

  // <div class="lobbyLeaderboardScoreListItem">2,452,346,563</div>
  // var iDiv = document.createElement("div");
  // iDiv.id = "block";
  // iDiv.className = "block";
  // // Create the inner div before appending to the body
  // var innerDiv = document.createElement("div");
  // innerDiv.className = "block-2";
  // // The variable iDiv is still good... Just append to it.
  // iDiv.appendChild(innerDiv);
  // // Then append the whole thing onto the body
  // document.getElementsByTagName("body")[0].appendChild(iDiv);
  // tb = document.createElement("tbody");
  // var tbody = document.createElement("tbody");
  // table.appendChild(tbody);
  // var table_row = document.createElement("tr");
  // tbody.appendChild(table_row); //
}

// function populateArpabet() {}
globalThis.handleDefinitionSearch = () => {
  // filter the definition div if it doesn't contain search input text
  const definitionSearch = document.querySelector("#definitionSearch");
  const definitionSearchInput = definitionSearch.value;
  if (!definitionSearchInput || definitionSearchInput == "") {
    return populateDefinitions(globalThis.defintionByTargetWord);
  }
  console.log(definitionSearchInput);
  globalThis.defintionByTargetWord;
  let filteredDefintionByTargetWord = {};
  for (const [word, definition] of Object.entries(
    globalThis.defintionByTargetWord
  )) {
    if (word.includes(definitionSearchInput)) {
      filteredDefintionByTargetWord[word] = definition;
    }
  }

  return populateDefinitions(filteredDefintionByTargetWord);
};
function populateDefinitions(defintionByTargetWord) {
  const lobbyDefinitionsList = document.querySelector("#lobbyDefinitionsList");
  lobbyDefinitionsList.textContent = "";

  // var tbody = document.createElement("tbody");
  // tbody.style.with = "200px";
  // lobbyDefinitionsTable.appendChild(tbody);
  // log("globalThis.targetWordDefinitions");
  log("globalThis.defintionByTargetWord");
  log(Object.entries(defintionByTargetWord));
  for (const [word, definition] of Object.entries(defintionByTargetWord)) {
    // console.log("${word} ${definition}");
    // console.log(`${word} ${definition}`);
    const wordDiv = document.createElement("div");
    wordDiv.textContent = word.toUpperCase();
    wordDiv.style.textDecoration = "bold";
    const definitionDiv = document.createElement("div");
    definitionDiv.textContent = definition;
    // if (
    //   definition.definition.slice(-1) != "." &&
    //   definition.definition.slice(-1) != "]"
    // ) {
    //   definitionDiv.textContent += "...";
    // }
    definitionDiv.style.fontSize = "12";
    // wordDiv.style.textDecoration = "underline";
    wordDiv.style.fontWeight = "900";
    wordDiv.style.marginTop = "8px";

    definitionDiv.style.fontWeight = "normal";
    definitionDiv.style.textDecoration = "none";

    // const verticalSpaceDiv = document.createElement("div");
    // verticalSpaceDiv.style.height = "8px";

    lobbyDefinitionsList.appendChild(wordDiv);
    lobbyDefinitionsList.appendChild(definitionDiv);
    // lobbyDefinitionsList.appendChild(verticalSpaceDiv);
  }
  // console.log("globalThis.seenTargetWords");
  // console.log(globalThis.seenTargetWords);
  // if (globalThis.seenTargetWords) {
  if (globalThis.targetWords) {
    // if (globalThis.seenTargetWords.length > 0) {
    // globalThis.seenTargetWords.forEach((seenTargetWord) => {
    // globalThis.targetWords.forEach((targetWord) => {
    //   const seenTargetWord = targetWord;
    //   log(seenTargetWord);
    //   log(globalThis.defintionByTargetWord);
    //   const definition = globalThis.defintionByTargetWord[seenTargetWord];
    //   if (!definition) {
    //     return console.log(`could not find definition for ${seenTargetWord}`);
    //   }
    //   console.log("definition");
    //   console.log(globalThis.defintionByTargetWord[seenTargetWord]);
    //   const wordDiv = document.createElement("div");
    //   wordDiv.textContent = seenTargetWord;
    //   wordDiv.style.textDecoration = "bold";
    //   const definitionDiv = document.createElement("div");
    //   definitionDiv.textContent = definition;
    //   // if (
    //   //   definition.definition.slice(-1) != "." &&
    //   //   definition.definition.slice(-1) != "]"
    //   // ) {
    //   //   definitionDiv.textContent += "...";
    //   // }
    //   definitionDiv.style.fontSize = "12";
    //   // wordDiv.style.textDecoration = "underline";
    //   wordDiv.style.fontWeight = "900";
    //   definitionDiv.style.fontWeight = "normal";
    //   definitionDiv.style.textDecoration = "none";
    //   const verticalSpaceDiv = document.createElement("div");
    //   verticalSpaceDiv.style.height = "8px";
    //   lobbyDefinitionsList.appendChild(wordDiv);
    //   lobbyDefinitionsList.appendChild(definitionDiv);
    //   lobbyDefinitionsList.appendChild(verticalSpaceDiv);
    // });
    // }
  }
}
function hideCreateAccountLoginButtonRow() {
  const createAccountLoginButtonRow = document.querySelector(
    "#createAccountLoginButtonRow"
  );
  createAccountLoginButtonRow.style.display = "none";
}
function hideLoginFormList() {
  const loginButton = document.querySelector("#loginButton");
  const logInFormList = document.querySelector("#logInFormList");
  logInFormList.style.display = "none";
  loginButton.style.color = "rgba(128, 255, 0, 0.715)";
}
function hideCreateUserFormList() {
  const createAccountButton = document.querySelector("#createAccountButton");
  const createAccountFormList = document.querySelector(
    "#createAccountFormList"
  );
  createAccountFormList.style.display = "none";
  createAccountButton.style.color = "rgba(128, 255, 0, 0.715)";
}
function showCreateUserFormList() {
  const createAccountFormList = document.querySelector(
    "#createAccountFormList"
  );
  createAccountFormList.style.display = "block";
}
function showPlayerProfileContainer() {
  const playerProfileContainer = document.querySelector(
    "#playerProfileContainer"
  );
  playerProfileContainer.style.display = "block";
}
function hidePlayerProfileContainer() {
  const playerProfileContainer = document.querySelector(
    "#playerProfileContainer"
  );
  playerProfileContainer.style.display = "none";
}
function hideLobbyLeaderboardList() {
  // console.log("hide lobby leaderboard list");
  const leaderboardButton = document.querySelector("#leaderboardButton");
  const leaderboard = document.querySelector("#lobbyLeaderboardList");
  const lobbyLeaderboardDescriptor = document.querySelector(
    "#lobbyLeaderboardDescriptor"
  );
  const nextRoundDifficulty = document.querySelector("#nextRoundDifficulty");
  if (nextRoundDifficulty) {
    nextRoundDifficulty.style.display = "none";
  }
  lobbyLeaderboardDescriptor.style.display = "none";
  leaderboardButton.style.color = "grey";
  leaderboard.style.display = "none";
  // }
  // console.log("leaderboard.style.display");
  // console.log(leaderboard.style.display);
}
function showLobbyLeaderboardList() {
  const nextRoundDifficulty = document.querySelector("#nextRoundDifficulty");
  const leaderboard = document.querySelector("#lobbyLeaderboardList");
  const leaderboardButton = document.querySelector("#leaderboardButton");
  const lobbyLeaderboardDescriptor = document.querySelector(
    "#lobbyLeaderboardDescriptor"
  );
  leaderboard.style.display = "flex";
  leaderboardButton.style.color = "white";
  lobbyLeaderboardDescriptor.style.display = "grid";
  if (nextRoundDifficulty) {
    nextRoundDifficulty.style.display = "block";
  }
}
function showLobbyDefinitionsList() {
  const definitions = document.querySelector("#lobbyDefinitionsListContainer");
  const definitionsButton = document.querySelector("#definitionsButton");
  definitionsButton.style.color = "white";
  definitions.style.display = "grid";
}
function hideLobbyDefinitionsList() {
  const definitionsButton = document.querySelector("#definitionsButton");
  const definitions = document.querySelector("#lobbyDefinitionsListContainer");
  definitions.style.display = "none";
  definitionsButton.style.color = "grey";
}
function handleClickLeaderboard() {
  hideMenus();
  showLobbyLeaderboardList();
  // hideLobbyDefinitionsList();
  // hideCreateUserFormList();
  // hideLoginFormList();
}
function handleClickDefinitions() {
  hideMenus();
  showLobbyDefinitionsList();
  // hideLobbyLeaderboardList();
  // hideCreateUserFormList();
  // hideLoginFormList();
}
// Load spaceship
loadSpaceship();
function loadSpaceship() {
  const gltfLoader = new GLTFLoader();
  const url = "models/spacecraft7.glb";
  gltfLoader.load(url, (gltf) => {
    const ship = gltf.scene;
    // ship.scale.set(0.2, 0.2, 0.2);
    ship.position.y = -1;
    ship.position.z = 6;
    ship.rotation.x = -0.2;
    globalThis.ship = ship;
    scene.add(ship);
  });
}
// Handle Show Lobby Leaderboard
// showLobbyLeaderboard();
// function showLobbyLeaderboard() {
//   const element = document.querySelector("#lobbyLeaderboardList");
//   element.style.display = "none";
// }
// Handle Show Lobby Definitions
function showLobbyDefinitions() {}
const initSqlJs = window.initSqlJs;
// // Opt In to Next Round

// Score
globalThis.score = 0;
/*
 * Load Words DB
 */
// async function loadWordsDb() {
//   const sqlPromise = initSqlJs({
//     locateFile: (file) => `https://sql.js.org/dist/${file}`,
//   });
//   const dataPromise = fetch(
//     "https://storage.googleapis.com/rwu-words-bucket/words.db"
//   ).then((res) => res.arrayBuffer());

//   // console.log(dataPromise);
//   const [SQL, buf] = await Promise.all([sqlPromise, dataPromise]);
//   const wordsDb = new SQL.Database(new Uint8Array(buf));
//   // console.log("words db");
//   // console.log(wordsDb);
//   globalThis.wordsDb = wordsDb;
//   // Prepare an sql statement
//   // const stmt = db.prepare("SELECT * FROM hello WHERE a=:aval AND b=:bval");

//   // // Bind values to the parameters and fetch the results of the query
//   // const result = stmt.getAsObject({ ":aval": 1, ":bval": "world" });
//   // console.log(result); // Will print {a:1, b:'world'}
//   // stmt.free();
// }
// loadWordsDb();
function getValueFromFirebase(path) {
  // console.log("getValue Path path");
  // console.log(path);
  const promise = new Promise((resolve, reject) => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, path))
      .then((snapshot) => {
        if (snapshot.exists()) {
          // console.log(`value retreived from firebase ${snapshot.val()}`);
          resolve(snapshot.val());
        } else {
          reject();
          console.log("No data available");
        }
      })
      .catch((error) => {
        reject();
        console.error(error);
      });
  });
  return promise;
}
async function getArpabetForTargetWords(targetWords) {
  globalThis.promptArpabetByPrompt = {};
  for (const targetWord of targetWords) {
    // console.log("targetWord");
    // console.log(targetWord);
    const arpabet = await getValueFromFirebase(
      `/arpabet/${serverUid}/${targetWord.replace(/[^a-zA-Z ]/g, "")}`
    ).catch((error) => {
      console.log(error);
    });

    if (!arpabet) {
      console.log(`no arpabet for ${targetWord}`);
      continue;
    }

    globalThis.promptArpabetByPrompt[targetWord] = JSON.parse(arpabet);
  }
}
async function getDefinitionsForTargetWords(targetWords) {
  let defintionByTargetWord = {};
  for (const targetWord of targetWords) {
    // console.log("targetWord");
    // console.log(targetWord);
    const definition = await getValueFromFirebase(
      `/definitions/${serverUid}/${targetWord}`
    ).catch((error) => {
      console.log(error);
    });

    if (!definition) {
      console.log(`no definition for ${targetWord}`);
    }

    defintionByTargetWord[targetWord] = definition;
  }
  log("getDefinitionsForTargetWords");
  globalThis.defintionByTargetWord = defintionByTargetWord;
  populateDefinitions(defintionByTargetWord);
  // targetWords.forEach((targetWord) => {
  //   if (!!globalThis.wordsDb) {
  //     const queryResult = globalThis.wordsDb.exec(
  //       `SELECT name, definition FROM words where name="${targetWord}"`
  //     );
  //     // console.log("definition queryResult");
  //     // console.log(queryResult[0].values[0][0]);
  //     // console.log(queryResult[0].values[0][1]);
  //     targetWordDefinitions.push({
  //       word: queryResult[0].values[0][0],
  //       definition: queryResult[0].values[0][1],
  //     });
  //   }
  // });
}
function getWordFromDb(wordName) {
  console.log(`getwordfromdb wordname ${wordName}`);
  // if (!!globalThis.wordsDb) {
  //   const queryResult = globalThis.wordsDb.exec(
  //     `SELECT * FROM words where name="${wordName}"`
  //   );
  //   console.log("queryResult");
  //   return queryResult[0].values[0];
  // }
}
async function getArpabetFromDb(wordName) {
  console.log(`getArpabetFromDb wordname ${wordName}`);
  const arpabet = await getValueFromFirebase(
    `/arpabet/${serverUid}/${wordName}`
  );
  console.log("arpabet");
  console.log(arpabet);
  return arpabet;
  // if (!!globalThis.wordsDb) {
  //   const queryResult = globalThis.wordsDb.exec(
  //     `SELECT arpabet FROM words where name = "${wordName}"`
  //   );
  //   console.log("queryResult");
  //   console.log(queryResult);
  //   if (queryResult.length > 0) {
  //     return queryResult[0].values[0];
  //   } else return undefined;
  // }
}
/*
 *  Rounds
 */
// globalThis.joinNextRound = false;
globalThis.roundState = "LOBBY";
// globalThis.handleJoinNextRound = handleJoinNextRound;

const TEXT_START_Z = -30;
// function handleJoinNextRound() {
//   console.log("Join!");
//   document.getElementById("joinNextRoundButton").className = "button-71-active";
//   globalThis.joinNextRound = true;
// }

// function setTimerText(timeToNextRound) {
//   const playingTimer = document.querySelector("#joinNextRoundButton");

//   // console.log(timeToNextRound);
//   if (globalThis.roundState != "LOBBY") {
//     joinNextRoundButton.style.display = "none";
//   } else {
//     joinNextRoundButton.style.display = "block";
//   }
//   let buttonStatus;
//   if (!globalThis.joinNextRound) {
//     buttonStatus = "Join Next Round!";
//   } else {
//     buttonStatus = "Next Round In";
//   }
//   // element.textContent;
//   buttonStatus += ` ${Math.floor(timeToNextRound / 1000)}s`;
//   if (timeToNextRound) {
//     joinNextRoundButton.textContent = buttonStatus;
//   }
// }
// // todo: UI for this!
// // Once the join next round button is clicked set this globalThis value to true
// // WHen it's true then let the Playing state trigger the game to start

// // Scene
const scene = new THREE.Scene();

// const light_2 = new THREE.DirectionalLight(0xffffff);
// const light_3 = new THREE.AmbientLight(0xffffff, 0.3);

// light_4.position.z = -50;
// light_2.position.set(10, 10, 10);
// light_2.intensity = 1.1;
// light_2.castShadow = true;
// scene.add(light_2);
// scene.add(light_3);

// Texture Loader
const textureLoader = new THREE.TextureLoader();
const earthColorTextureTif = textureLoader.load(
  "/textures/earth/earth_color_10K.png"
);
const earthRoughnessMap = textureLoader.load(
  "/textures/earth/earth_landocean_4K.png"
);
const earthHeightMap = textureLoader.load("/textures/earth/topography_5K.png");
// console.log("earthColorTextureTif");
// console.log(earthColorTextureTif);

// /**
//  * Space Clouds
//  */

// /**
//  * Models
//  */

const earthGeometry = new THREE.SphereGeometry(1, 2056, 512);
const atmosphereGeometry = new THREE.SphereGeometry(1, 1024, 256);
const earthMaterial = new THREE.MeshStandardMaterial({
  map: earthColorTextureTif,
  roughnessMap: earthRoughnessMap,
  displacementMap: earthHeightMap,
  displacementScale: 0.05,
});
earthMaterial.onBeforeCompile = function (shader) {
  shader.fragmentShader = shader.fragmentShader.replace(
    "#include <roughnessmap_fragment>",
    //the line '#include <roughnessmap_fragment>' will replaced with text from THREE.ShaderChunk.roughnessmap_fragment
    //there is a line "vec4 texelRoughness = texture2D( roughnessMap, vUv );"
    //we need "invert" a value getting from texture: texelRoughness = 1. - texture2D( roughnessMap, vUv )
    THREE.ShaderChunk.roughnessmap_fragment.replace(
      "texelRoughness =",
      "texelRoughness = 1. -"
    )
  );
};
const atmosphereTexture = textureLoader.load("textures/smoke/fog3.png");
// const atmosphereMaterial = new THREE.MeshBasicMaterial({ color: "blue" });
const atmosphereMaterial = new THREE.MeshStandardMaterial({
  transparent: true,
  map: atmosphereTexture,
});
function setEarth() {
  const earth = new THREE.Mesh(earthGeometry, earthMaterial);
  const atmosphere = new THREE.Mesh(atmosphereGeometry, atmosphereMaterial);
  // console.log("earth");
  // console.log(earth);
  earth.rotation.y = Math.PI / getRandomInt(0, 8);
  earth.rotation.x = Math.PI / getRandomInt(0, 8);
  earth.position.z = 0;
  // earth.position.x = getRandomInt(-3, 3);
  earth.position.x = -1;
  // console.log("earth.position.x");
  // console.log(earth.position.x);
  // console.log(earth.position.y);
  // console.log(earth.position.z);
  // console.log(earth);
  earth.position.y = -10;
  earth.scale.set(10, 10, 10);
  atmosphere.scale.set(11, 11, 11);

  // earth.rotation.x = Math.PI / 2;
  // earth.rotation.y = Math.PI / 2;
  earth.rotation.z = Math.PI / 2;
  // earth.material.transparent = true;
  // earth.material.opacity = 0;

  // // console.log("earth");
  // // console.log(earth);
  globalThis.earth = earth;
  globalThis.atmosphere = atmosphere;
  attachAtmosphere();
  scene.add(earth);
  scene.add(atmosphere);
  // console.log(atmosphere);

  // console.log("atmoshere");
}

setEarth();
function attachAtmosphere() {
  globalThis.atmosphere.position.set(
    earth.position.x,
    earth.position.y,
    earth.position.z
  );
  globalThis.atmosphere.rotation.set(
    earth.rotation.x,
    earth.rotation.y,
    earth.rotation.z
  );
}
const light_5 = new THREE.SpotLight(0xcc6600, 2);
const light_4 = new THREE.SpotLight(0xffffff, 2);
scene.add(light_4);
// scene.add(light_5);
// /**
//  * Rhyme Dictionary
//  */
// console.log("rhymeDict");
// console.log(Object.keys(rhymeDict).length);

// /**
//  * Firebase
//  */
const firebaseConfig = {
  apiKey: "AIzaSyAFS7O64m_FOVnWlR4gw4gv3bxxVY_kwtw",
  authDomain: "rhyme-with-us.firebaseapp.com",
  projectId: "rhyme-with-us",
  storageBucket: "rhyme-with-us.appspot.com",
  messagingSenderId: "352365821237",
  appId: "1:352365821237:web:b212f87ba1b0720ce1e07c",
};

// Initialize Firebase
const db = getDatabase(initializeApp(firebaseConfig));

// onValue(
//   ref(db, `/my-score/${globalThis.myLevel}/${globalThis.myUsername}`),
//   (snapshot) => {
//     const existingScore = snapshot.val();
//     globalThis.existingScore = existingScore;
//   }
// );

getAuth().onAuthStateChanged((user) => {
  if (user) {
    // User logged in already or has just logged in.
    // console.log("user.uid");
    // console.log(user.uid);
    hideCreateAccountLoginButtonRow();
    getMyUsername(user.uid);
  } else {
    // User not logged in or has just logged out.
  }
});

// Current Round Difficulty
onValue(ref(db, `/round-difficulty/${serverUid}/current`), (snapshot) => {
  if (snapshot.exists()) {
    setCurrentDifficulty(snapshot.val());
  }
});
onValue(ref(db, `/round-difficulty/${serverUid}/next`), (snapshot) => {
  if (snapshot.exists()) {
    setNextDifficulty(snapshot.val());
  }
});

function setNextDifficulty(difficulty) {
  const currentDifficultyDiv = document.querySelector("#nextDifficulty");
  let newDifficulty = "";
  for (let i = 0; i < difficulty; i++) {
    newDifficulty += `⭐ `;
  }
  currentDifficultyDiv.textContent = `Next Round Difficulty: ${newDifficulty}`;
}
function hideNextDifficulty() {
  const nextDifficultyDiv = document.querySelector("#nextDifficulty");

  nextDifficultyDiv.style.display = "none";
}
function setCurrentDifficulty(difficulty) {
  const currentDifficultyDiv = document.querySelector("#currentDifficulty");
  let newDifficulty = "";
  for (let i = 0; i < difficulty; i++) {
    newDifficulty += `⭐ `;
  }
  currentDifficultyDiv.textContent = `${newDifficulty}`;
}

// My XP
onValue(ref(db, `/my-xp/${serverUid}/${globalThis.myUsername}`), (snapshot) => {
  if (snapshot.exists()) {
    const newXp = snapshot.val() * -1;
    log("newXp");
    log(newXp);
    log("typeof globalThis.myXp");
    log(typeof globalThis.myXp);
    if (typeof globalThis.myXp === "undefined") {
      console.log("NO EXISTING XP. myXp = newXp");
      // myXp = 0;

      globalThis.myXp = newXp;
      console.log("globalThis.myXp");
      console.log(globalThis.myXp);
    } else {
      console.log("FOUND EXISTING XP. myXp exists");
    }
    console.log("onValue /my-xp globalThis.myXp");
    console.log(globalThis.myXp);
    let myXp = globalThis.myXp;
    setXpBar({ myXp, newXp });
    globalThis.myXp = newXp;
  } else {
    globalThis.myXp = 0;
    globalThis.myLevel = 1;
    setUserNameLabel();
  }
});

// My Rank
onValue(
  ref(db, `/my-rank/${serverUid}/${globalThis.myUsername}`),
  (snapshot) => {
    if (snapshot.exists()) {
      const myRank = snapshot.val();
      console.log("myRank");
      console.log(myRank);
      globalThis.myRank = myRank;
      populateLeaderboard();
    }
  }
);
function getMyUsername(myUid) {
  const myUsernameRef = ref(db, `/my-username/${myUid}`);
  onValue(myUsernameRef, (snapshot) => {
    const myUsername = snapshot.val();
    localStorage.myUsername = myUsername;
    globalThis.myUsername = myUsername;
    log("globalThis.myUsername");
    log(globalThis.myUsername);

    // onValue(ref(db, `/my-rank/${serverUid}/${globalThis.myUsername}`),() => (
    //   (snapshot) => {
    //     if (snapshot.exists()) {
    //       const myRank = snapshot.val();
    //       console.log("myRank");
    //       console.log(myRank);
    //     }
    //   }
    // );
    setUserNameLabel();
  });
}

function setScoreText() {
  // console.log("Set Score");
  // console.log(globalThis.score);
  const playingScore = document.querySelector("#playingScore");
  playingScore.textContent = `Rhyme Score: ${globalThis.score}`;
}

// Leaderboard
const leaderboardRef = ref(db, `/leaderboard/${serverUid}`);
onValue(leaderboardRef, (snapshot) => {
  // console.log("On Leaderboard!");
  let leaderboardEntries = [];
  snapshot.forEach((leaderboardEntry) => {
    const leaderboardEntryObj = leaderboardEntry.val();

    leaderboardEntries.push({
      username: Object.keys(leaderboardEntryObj)[0],
      score: Object.values(leaderboardEntryObj)[0] * -1,
    });
  });
  populateLeaderboard(leaderboardEntries);
  // log(leaderboardEntries);
  // globalThis.leaderboard = ;
});

const interval = setInterval(setTimerText, 1000);

// clearInterval(interval);

function setTimerText() {
  // console.log("set timer text");
  const playingTimer = document.querySelector("#playingTimer");
  // playingTimer.style.display = "inline-block";
  const timeToNextRound = globalThis.timeToNextRound;
  let timerStatus;
  if (globalThis.roundState === "LOBBY") {
    if (globalThis.timeToNextRound < 0) {
      globalThis.globalThis.roundEndTime += 60000;
      globalThis.timeToNextRound = globalThis.roundEndTime - Date.now();
    }
    timerStatus = "Next Round Starts in";
  } else {
    timerStatus = "Round Ends In";
  }

  timerStatus += ` ${
    timeToNextRound > 0 ? Math.floor(timeToNextRound / 1000) : "..."
  }s`;
  if (timeToNextRound) {
    playingTimer.textContent = timerStatus;
  }
  if (!timerStatus.includes("...")) {
    globalThis.timerIsLoaded = true;
  }
}

const roundEndTimeRef = ref(db, `/round-end-time/${serverUid}`);
onValue(roundEndTimeRef, (snapshot) => {
  const roundEndTime = snapshot.val();
  if (snapshot.exists()) {
    globalThis.roundEndTime = roundEndTime;

    setTimerText();
  }
});
function startTargetWords() {
  globalThis.sendWordsInterval = setInterval(globalThis.sendWords, 9000);
  startSendWordAnimation();
}
function stopTargetWords() {
  clearInterval(globalThis.sendWordsInterval);
  stopSendWordAnimation();
}
function hideSpaceBg() {
  if (globalThis.spaceBg) {
    globalThis.spaceBg.material.opacity = 0;
  }
}
const roundStateRef = ref(db, `/round-state/${serverUid}`);
onValue(roundStateRef, (snapshot) => {
  const roundState = snapshot.val();
  // console.log("ROUND STATE");
  globalThis.roundState = roundState;
  if (roundState === "LOBBY") {
    // populateDefinitions();
    // globalThis.joinNextRound = false;
    /*
     *  Scores have to be saved continuously to firebase to decrease leaderboard latency
     *       by doing the calculation on the server at the end of the round
     *
     *  Users start out as guests
     *  Guests function just like users for the purposes of the game and leaderboard
     *  Each guest name is unique
     *  Users can change their guest name by creating an account
     *      Firebase, username and password (magic link looks cool, but is it easier or not?) (for me and user) (how does forgot password work?
     *
     * Be able to count guest users
     * Leaderboard gets overwritten every round
     *
     *
     * )
     */
    // playLobbyMusic();
    hideSpaceBg();

    stopTargetWords();
    // hideTargetWord();
    showLobbyLeaderboard();
    // if (!!globalThis.logo) {
    //   scene.add(globalThis.logo);
    // }
    globalThis.showLogo();
    hidePlayingScore();
    globalThis.myScore = 0;
    globalThis.myRank = "...";
    hideInputField();
    if (!!globalThis.earth) {
      // console.log("SHOW EARTH!");
      globalThis.earth.visible = true;
      0;
      globalThis.earth.material.opacity = 1;
    }
  } else {
    // PLAYING
    if (globalThis.stayInLobby) {
      return console.log("Still in lobby!");
    }
    setSpaceBg();
    // playPlayingMusic();
    globalThis.seenTargetWords = [];

    startTargetWords();
    hideLobbyLeaderboard();
    globalThis.score = 0;
    setScoreText();

    // if (!!globalThis.logo) {
    //   scene.remove(globalThis.logo);
    // }
    globalThis.hideLogo();
    globalThis.roundState = roundState;
    showInputField();

    showPlayingScore();
  }

  // console.log(`/round-state: ${roundState}`);
  //   updateStarCount(postElement, data);
});
function hideLobbyLeaderboard() {
  const element = document.querySelector("#lobbyLeaderboardContainer");
  element.style.display = "none";
}
function showLobbyLeaderboard() {
  const element = document.querySelector("#lobbyLeaderboardContainer");
  element.style.display = "flex";
}
function hidePlayingScore() {
  const element = document.querySelector("#playingScore");
  element.style.visibility = "hidden";
}
function showPlayingScore() {
  const element = document.querySelector("#playingScore");
  element.style.visibility = "visible";
}
// Target Words
const targetWordsRef = ref(db, `/target-words/${serverUid}/`);
onValue(targetWordsRef, (snapshot) => {
  // const targetWordsByLevel = snapshot.val();
  // console.log("targetWordsByLevel");
  // console.log(targetWordsByLevel);

  globalThis.targetWords = snapshot.val();
  getDefinitionsForTargetWords(globalThis.targetWords);
  getArpabetForTargetWords(globalThis.targetWords);

  // console.log("globalThis.targetWordDefinitions");
  // console.log(globalThis.targetWordDefinitions);
  globalThis.currentTargetWordIndex = 0;
  // console.log("globalThis.targetWords[globalThis.targetWordIndex]");
  // console.log(globalThis.targetWords[globalThis.currentTargetWordIndex]);
  const targetWord = globalThis.targetWords[globalThis.currentTargetWordIndex];
  if (!globalThis.seenTargetWords) {
    globalThis.seenTargetWords = [];
  }
  globalThis.seenTargetWords.push(targetWord);
  // populateDefinitions();
  globalThis.setText(targetWord);
  globalThis.currentTargetWordIndex++;
});

// todo:
// Database
//  Get the arpabet for the target word only once from the db
//  get the arpabet for the input word each submit
// Rhyme Score
//  Compare the rhymes
//  Generate a score
// UI Feedback
//  Show Dopamine Feedback for successful rhymes (particles, flashing, shaders, colors, etc.)
//  css attack text! float up and fade out, be fast and snappy. We'd have to figure out positioning - there's a tutorial!

// console.log(firebaseDatabase);
/**
 * Base
 */
// Debug
// const gui = new dat.GUI();

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Load Font

function loadFont(fontPath) {
  return new Promise((resolve, reject) => {
    const fontLoader = new FontLoader();
    try {
      fontLoader.load(fontPath, (font) => {
        // console.log("loaded");
        resolve(font);
      });
    } catch {
      reject();
      console.error("Error loading font");
    }
  });
}

// Input
function sendAttackText() {
  const attackTextDiv = document.querySelector("#attackText");
  attackTextDiv.style.display = "flex";
  attackTextDiv.classList.add("sendAttackWordAnimation");
  attackTextDiv.textContent = globalThis.input.toUpperCase();
  // console.log(attackTextDiv);
  const attackTextOffset = getRandomInt(
    globalThis.sizes.width / 2 - 90,
    globalThis.sizes.width / 2 + 70
  );
  attackTextDiv.style.left = `${attackTextOffset}px`;
  // console.log("attackTextOffset");
  // console.log(attackTextOffset);
  setTimeout(stopAttackText, 500);
}
function stopAttackText() {
  const attackTextDiv = document.querySelector("#attackText");
  attackTextDiv.style.display = "none";
  attackTextDiv.classList.remove("sendAttackWordAnimation");
}
globalThis.handleSubmit = async (e) => {
  // alert("you press enter");
  const inputValue = getInputValue();
  globalThis.input = inputValue;
  handle2dSubmit();
  function handle2dSubmit() {
    sendAttackText();
    globalThis.generateRhymeScore({
      inputValue,
      prompt: globalThis.prompt,
    });
    // Has to be username instead of UID because I don't want to have to log in guest users

    // replayAttackTextAnimation();
  }
  setInputValue("");
};
function getInputValue() {
  const element = document.querySelector("#inputField");
  globalThis.userInput = element.value;
  return element.value;
}
function hideInputField() {
  const element = document.querySelector("#inputField");
  element.hidden = true;
}
function showInputField() {
  const element = document.querySelector("#inputField");
  element.hidden = false;
}
function setInputValue(inputValue) {
  const element = document.querySelector("#inputField");
  element.value = "";
}

function disposeAttackText(attackText) {
  // console.log("Dispose Attack Text ");
  attackText.geometry.dispose();
  attackText.material.dispose();
  scene.remove(attackText);
  globalThis.attackTextZ = undefined;
  const index = globalThis.attackTexts.indexOf(attackText);
  if (index > -1) {
    globalThis.attackTexts.splice(index, 1);
    globalThis.attackWords.splice(index, 1);
  }
}
function disposeEarthModel(earth) {
  scene.remove(earth);
  globalThis.earth = undefined;
}

/**
 * Sizes
 */
globalThis.sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  console.log("Resize");
  // Update sizes
  globalThis.sizes.width = window.innerWidth;
  globalThis.sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = globalThis.sizes.width / globalThis.sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(globalThis.sizes.width, globalThis.sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  50,
  globalThis.sizes.width / globalThis.sizes.height,
  0.1,
  1000
);
camera.position.z = 10;
// camera.fov = 10;
//   camera.rotation.y = Math.PI / 2;
scene.add(camera);
//   // Controls
//   const controls = new OrbitControls(camera, canvas);
//   controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.setSize(globalThis.sizes.width, globalThis.sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

// scene.fog = new THREE.FogExp2(0x03544e, 0.00001);
// renderer.setClearColor(scene.fog.color);
globalThis.spaceBgIndex = 5;
setSpaceBg();

function setSpaceBg() {
  if (globalThis.roundState != "PLAYING") {
    return;
  }
  if (!!globalThis.spaceBg) {
    scene.remove(globalThis.spaceBg);
  }
  globalThis.spaceBgIndex++;
  if (globalThis.spaceBgIndex > 5) {
    globalThis.spaceBgIndex = 1;
  }
  const spaceBgIndex = globalThis.spaceBgIndex;
  console.log("spaceBgIndex");
  console.log(spaceBgIndex);
  const spaceBgTexture = textureLoader.load(
    `/textures/spaceBg/spaceBg${spaceBgIndex}.jpg`
  );
  const spaceBgGeo = new THREE.PlaneBufferGeometry(150, 100);
  const spaceBgMaterial = new THREE.MeshLambertMaterial({
    map: spaceBgTexture,
    transparent: true,
  });
  let spaceBg = new THREE.Mesh(spaceBgGeo, spaceBgMaterial);
  spaceBg.material.opacity = 0;
  spaceBg.position.z = -50;
  globalThis.spaceBg = spaceBg;
  console.log("spaceBg");
  console.log(spaceBg);
  scene.add(spaceBg);
}
function rotateSpaceBg(elapsedTime) {
  if (globalThis.spaceBg) {
    globalThis.spaceBg.rotation.z = elapsedTime * 0.001;
  }
}
// const cloudTexture = textureLoader.load("/textures/smoke/smoke.png");
// const cloudGeo = new THREE.PlaneBufferGeometry(150, 100);
// const cloudMaterial = new THREE.MeshLambertMaterial({
//   map: cloudTexture,
//   transparent: true,
// });
// let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
// // scene.add(cloud);
// cloud.position.z = 5;
// cloud.position.x = 50;
// cloud.position.y = 10;
// scene.add(cloud);
// globalThis.cloud = cloud;
let directionalLight = new THREE.DirectionalLight(0xff8c19);
directionalLight.position.set(100, 100, 10);
// scene.add(directionalLight);

let orangeLight = new THREE.PointLight(0xcc6600, 0.5, 1000, 3);
// let orangeLight = new THREE.DirectionalLight(0xcc6600, 0.5);
orangeLight.position.set(100, 300, 100);
// orangeLight.lookAt(globalThis.earth);
scene.add(orangeLight);

let redLight = new THREE.PointLight(0xd8547e, 1, 450, 1.7);
redLight.position.set(100, 300, 100);
scene.add(redLight);

let blueLight = new THREE.PointLight(0x3677ac, 1, 450, 1.7);
blueLight.position.set(300, 300, 100);
scene.add(blueLight);

let cloudParticles = [];
// for (let p = 0; p < 50; p++) {
//   let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
//   // cloud.position.set(Math.random() * 800 - 400, 500, Math.random() * 500 - 500);
//   cloud.position.set(0, 0, 10);
//   cloud.rotation.x = 1.16;
//   cloud.rotation.y = -0.12;
//   cloud.rotation.z = Math.random() * 2 * Math.PI;
//   cloud.material.opacity = 1;
//   cloudParticles.push(cloud);
//   scene.add(cloud);
//   console.log("cloud");
//   console.log(cloud);
// }
const fragmentShader = `
#include <common>
 
uniform vec3 iResolution;
uniform float iTime;
 
// By iq: https://www.shadertoy.com/user/iq  
// license: Creative Commons Attribution-NonCommercial-ShareAlike 3.0 Unported License.
void mainImage( out vec4 fragColor, in vec2 fragCoord )
{
    // Normalized pixel coordinates (from 0 to 1)
    vec2 uv = fragCoord/iResolution.xy;
 
    // Time varying pixel color
    vec3 col = 0.5 + 0.5*cos(iTime+uv.xyx+vec3(0,2,4));
 
    // Output to screen
    fragColor = vec4(col,1.0);
}
 
void main() {
  mainImage(gl_FragColor, gl_FragCoord.xy);
}
`;
const uniforms = {
  iTime: { value: 0 },
  iResolution: { value: new THREE.Vector3() },
};
function startSendWordAnimation() {
  document.querySelector("#targetWord").style.display = "table-cell";
}

function hideScoreEffectAnimation() {
  document.querySelector("#scoreEffect").style.visibility = "hidden";
}
function replayScoreEffectAnimation(score) {
  const scoreEffectDiv = document.querySelector("#scoreEffect");
  console.log("replayScoreEffectAnimation");
  scoreEffectDiv.style.visibility = "visible";
  scoreEffectDiv.textContent = `+${score}`;
  setTimeout(hideScoreEffectAnimation, 1000);
  document.getAnimations().forEach((anim) => {
    if (anim.animationName == "sendScoreEffect") {
      anim.cancel();
      anim.play();
    }
  });

  console.log("replayScoreEffectAnimation");
  const targetWordDiv = document.querySelector("#scoreEffect");
  targetWordDiv.classList.add("sendScoreEffectAnimation");
}

// function replayAttackTextAnimation() {
//   document.getAnimations().forEach((anim) => {
//     if (anim.animationName == "sendAttackWord") {
//       anim.cancel();
//       anim.play();
//     }
//   });
// }

function replaySendWordAnimation() {
  document.getAnimations().forEach((anim) => {
    if (anim.animationName == "sendWord") {
      anim.cancel();
      anim.play();
    }
  });
}
function stopSendWordAnimation() {
  document.querySelector("#targetWord").style.display = "none";
  document.getAnimations().forEach((anim) => {
    if (anim.animationName == "sendWord") {
      anim.cancel();
      // anim.play();
    }
  });
}
// /**
//  * Begin Main
//  */
async function main() {
  // // getInputValue();
  // //   setInputValue("123");
  // /**
  //  * Font
  //  */playingScore
  // const fontIndex = getRandomInt(1, 6);
  const fontIndex = 4;
  const font = await loadFont(`/fonts/${fontIndex}.json`);
  // console.log("text fontIndex");
  // console.log(fontIndex);
  // const font = await loadFont("/fonts/Super Mario 256_Regular.json");
  // /**
  //  * End Main
  //  */
  // /**
  //  * Text Geometry
  //  */
  // // THIS WORD COMES FROM FIREBASE ARRAY
  // // ITERATE BASED ON RHYME OR Z VALUE
  // // TURN THIS TEXT GENERATION INTO A FUNCTION
  // // CREATE THE GEOMETRY AND SHOW AND HIDE IT AS NECESSARY
  // // DESTROY THE GEOMETRY ONCE IT'S RHYMED OR PAST THE CAMERA
  // // SHOW THE NEXT GEOMETRY AND SEND IT TO THE PLAYER
  // // LOOP THE ARRAY IF WE RUN OUT OF TARGET WORDS
  // // STORE CURRENT TARGET WORD IN AN INDEX
  // // ITERATE THE INDEX BASED ON Z and RHYMES
  // // WHEN THE INDEX IS ITERATED DESTROY OLD AND SEND NEW
  // /**
  //  * Textures
  //  */
  function getTextMatcap() {
    // return `/textures/matcaps/${getRandomInt(0, 18)}.png`;
    return `/textures/matcaps/4.png`;
  }
  globalThis.sendWords = sendWords;
  function sendWords() {
    console.log("SendWords");
    // const targetWordDiv = document.querySelector("#targetWord");
    globalThis.currentTargetWordIndex++;

    const newWord = globalThis.targetWords[globalThis.currentTargetWordIndex];
    globalThis.seenTargetWords.push(newWord);
    // populateDefinitions();
    console.log("newWord");
    console.log(newWord);
    setText(newWord);

    // targetWordDiv.style.webkitAnimationPlayState;

    // setText(globalThis.targetWords[globalThis.currentTargetWordIndex]);
    // targetWordDiv.style.animationName = "";
    // targetWordDiv.style.animationName = "sendWord";
  }

  globalThis.setText = setText;
  function setText(wordName) {
    // console.log("setText");
    // set the global arpabet for this word

    globalThis.targetWord = wordName;
    // console.log(wordName);
    // console.log("globalThis.targetWord");
    // console.log(globalThis.targetWord);
    // console.log("targetWord");
    // console.log(targetWord);
    //
    // if(globalThis.sizes.width > )

    // 3D
    const existingText = globalThis.text;
    if (!!existingText) {
      scene.remove(existingText);
      globalThis.text = undefined;
      globalThis.prompt = undefined;
    }
    globalThis.prompt = capitalizeFirstLetter(wordName);
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    // add3dText();
    add2dText();

    function add2dText() {
      // console.log("add 2d text");
      const targetWordDiv = document.querySelector("#targetWord");
      targetWordDiv.classList.add("sendWordAnimation");
      // targetWordDiv.className = "targetTextStyle";
      replaySendWordAnimation();
      targetWordDiv.textContent = globalThis.prompt;
    }

    function add3dText() {
      // const textMatcap = textureLoader.load("/textures/matcaps/16.png");
      // const textTexture = textureLoader.load("/textures/textures/2.jpg");
      // const textTexture = new THREE.TextureLoader().load(getTextMatcap());
      // console.log("textTexture");
      // console.log(textTexture);

      // const textMaterial = new THREE.MeshMatcapMaterial({
      //   matcap: textTexture,
      //   transparent: true,
      // });

      // Todo: Different Color for each Syllable!
      //  DO I know the syllables here?
      // Once I know the syllables, create a new material for each
      // Create geometries for each syllable
      // Create the syllable meshes
      // Position the syllable meshes next to each other
      // Add the group to the scene
      let textMaterial;
      if (!globalThis.nextWordBonus) {
        textMaterial = new THREE.MeshStandardMaterial({
          color: "white",
        });
      } else {
        textMaterial = new THREE.ShaderMaterial({
          fragmentShader,
          uniforms,
        });
      }

      const textGeometry = new TextGeometry(globalThis.prompt, {
        font: font,
        size: 0.75,
        height: 0.2,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.03,
        bevelSize: 0.02,
        bevelOffset: 0,
        bevelSegments: 5,
      });
      textGeometry.center();
      const text = new THREE.Mesh(textGeometry, textMaterial);
      text.material.transparent = true;
      text.position.z = TEXT_START_Z;
      globalThis.text = text;
      // console.log(`setText ${wordName}`);
      // text.material.opacity = 1;
      // console.log(text);
      scene.add(text);
      // console.log("scene");
      // console.log(scene);
    }
  }
  globalThis.showLogo = showLogo;
  function showLogo() {
    document.querySelector("#logo").style.visibility = "visible";
  }
  globalThis.hideLogo = hideLogo;
  function hideLogo() {
    // console.log("hide!");
    document.querySelector("#logo").style.visibility = "hidden";
  }
  setLogo();
  function setLogo() {
    // console.log("setLogo");
    set2dLogo();
    function set2dLogo() {
      showLogo();
    }
    function set3dLogo() {
      const logoTexture = new THREE.TextureLoader().load(getTextMatcap());
      // console.log("logoTexture");
      // console.log(logoTexture);
      const logoMaterial = new THREE.MeshMatcapMaterial({
        matcap: logoTexture,
        transparent: true,
      });
      // console.log("globalThis.sizes.width * 0.00075");
      // console.log(globalThis.sizes.width * 0.00075);
      const logoGeometry = new TextGeometry("Rhyme With Us", {
        font,
        size: 0.31,
        height: 0.15,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.03,
        bevelSize: 0.02,
        bevelOffset: 0,
        bevelSegments: 5,
      });
      logoGeometry.center();
      const logo = new THREE.Mesh(logoGeometry, logoMaterial);
      logo.position.z = 0;
      logo.position.x = 0;
      logo.position.y = 4.15;
      // console.log(`setLogo ${logo}`);
      // console.log(text);
      globalThis.logo = logo;
      scene.add(logo);
      // console.log("scene");
      // console.log(scene);
    }
  }

  /**
   * Stars
   */

  // const particlesGeometry = new THREE.BufferGeometry();
  // const bgParticlesGeometry = new THREE.BufferGeometry();
  // const count = 2500;

  // const positions = new Float32Array(count * 3); // Multiply by 3 because each position is composed of 3 values (x, y, z)
  // const bgPositions = new Float32Array(count * 3); // Multiply by 3 because each position is composed of 3 values (x, y, z)
  // const colors = new Float32Array(count * 3);

  // for (
  //   let i = 0;
  //   i < count * 3;
  //   i++ // Multiply by 3 for same reason
  // ) {
  //   positions[i] = (Math.random() - 0.5) * 20; // Math.random() - 0.5 to have a random value between -0.5 and +0.5
  //   colors[i] = Math.random();
  //   if (Math.random() > 0.2) {
  //     colors[i] = "ffffff";
  //   }
  // }
  // for (
  //   let i = 0;
  //   i < count * 3;
  //   i++ // Multiply by 3 for same reason
  // ) {
  //   bgPositions[i] = (Math.random() - 0.5) * 20; // Math.random() - 0.5 to have a random value between -0.5 and +0.5
  //   colors[i] = Math.random();
  //   if (Math.random() > 0.2) {
  //     colors[i] = "ffffff";
  //   }
  // }

  // particlesGeometry.setAttribute(
  //   "position",
  //   new THREE.BufferAttribute(positions, 3)
  // ); // Create the Three.js BufferAttribute and specify that each information is composed of 3 values
  // particlesGeometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));

  // bgParticlesGeometry.setAttribute(
  //   "position",
  //   new THREE.BufferAttribute(bgPositions, 3)
  // ); // Create the Three.js BufferAttribute and specify that each information is composed of 3 values
  // bgParticlesGeometry.setAttribute(
  //   "color",
  //   new THREE.BufferAttribute(colors, 3)
  // );

  // // particlesGeometry.setAttribute("emissive", "ffffff");

  // // Material

  // // let sprite = new THREE.TextureLoader().load("textures/particles/star1.png");
  // const sprite = new THREE.TextureLoader().load("/textures/particles/star.png");

  // const particlesMaterial = new THREE.PointsMaterial({
  //   size: 0.02,
  //   sizeAttenuation: true,
  //   map: sprite,
  //   // depthWrite: false,
  // });
  // particlesMaterial.transparent = true;
  // particlesMaterial.alphaMap = sprite;
  // particlesMaterial.vertexColors = true;
  // // Points
  // const particles = new THREE.Points(particlesGeometry, particlesMaterial);
  // const bgParticles = new THREE.Points(bgParticlesGeometry, particlesMaterial);
  // particles.rotateY(-Math.PI / 2);
  // scene.add(particles);

  /**
 * Old Stars
 * /

*/
  function randomNumberBetween(min, max) {
    // min and max included
    // return 0.1;
    // return Math.random() * (max - min) + min;
    const result = Math.random() * (max - min) + min;
    return result;
  }
  const starMaterial = new THREE.MeshBasicMaterial({ transparent: true });
  var starsGroup = new THREE.Group();
  const starsCount = 500;
  let stars = [];
  for (let i = 0; i < starsCount; i++) {
    const starGeometry = new THREE.SphereGeometry(
      randomNumberBetween(0.01, 0.05),
      8,
      2
    );
    // const starMatcap = textureLoader.load("/textures/matcaps/14.png");
    const star = new THREE.Mesh(starGeometry, starMaterial);
    // star.scale.set(1);
    star.position.z =
      ((Math.random() - 0.5) * globalThis.sizes.width) / 16 - 16;
    // star.position.z = -50;
    star.position.x = ((Math.random() - 0.5) * globalThis.sizes.width) / 16;
    star.position.y = ((Math.random() - 0.5) * globalThis.sizes.width) / 16;
    starsGroup.add(star);
    stars.push(star);
    scene.add(starsGroup);
  }
  // scene.add(starsGroup);

  /**
   * Text Collision
   */
  let seenPairs = {};
  // function checkTextCollision() {
  //   if (!globalThis.attackTextZ) return;
  //   if (globalThis.attackTextZ + 2 <= globalThis.textZ) {
  //     console.log(
  //       `globalThis.attackTextZ: ${globalThis.attackTextZ} ${
  //         globalThis.textZ
  //       } ${globalThis.attackTextZ <= globalThis.textZ}`
  //     );
  //     const prompt = globalThis.prompt;
  //     const attackWord = globalThis.attackWords[0];
  //     //   if (seenPairs[attackWord] === prompt) {
  //     //     return;
  //     //   }
  //     // Add these to a compare map
  //     //  If seen, ignore subsequent calls to this function
  //     console.log(
  //       `Compare words input: ${globalThis.attackWords[0]} prompt: ${prompt}`
  //     );
  //     // console.log("Disposing From Rhyme");
  //     // Todo: Create a powerup that allows player to destroy a word and get tons of extra points
  //     // Strategy is to use it for a word you don't want to rhyme
  //     // Any word will destroy the attack text, but a rhyme gives extra points
  //     disposeAttackText(attackTexts[0]);
  //     if (!seenPairs[attackWord]) {
  //       seenPairs[attackWord] = prompt;
  //     }

  //     // save score to FB!
  //     generateRhymeScore({
  //       input,
  //       prompt,
  //     });
  //     // Has to be username instead of UID because I don't want to have to log in guest users
  //     set(ref(db, `/my-score/${globalThis.myUsername}`), -1 * globalThis.score);
  //   }
  // }

  function getArpabetArrayFromArpabetNoStress(arpabet) {
    let arpabetArrayNoStress = [];
    getArpabetArrayFromArpabet(arpabet).forEach((arpPart) => {
      arpabetArrayNoStress.push(arpPart.replace(/[0-9]/g, ""));
    });
    return arpabetArrayNoStress;
  }
  function getArpabetArrayFromArpabet(arpabet) {
    const vowels = getVowels();
    let arpabetArray = [];
    arpabet.split(" ").forEach((arpPart) => {
      if (vowels.includes(arpPart.substring(0, 2))) {
        arpabetArray.push(arpPart);
      }
    });
    return arpabetArray;
  }
  function getVowels() {
    return [
      "AA",
      "AE",
      "AH",
      "AO",
      "AW",
      "AX",
      "AXR",
      "AY",
      "EH",
      "ER",
      "EY",
      "IH",
      "IX",
      "IY",
      "OW",
      "OY",
      "UH",
      "UW",
      "UX",
    ];
  }
  function getVowelProfileFromArpabetNoStress(arpabet) {
    return getVowelProfileFromArpabet(arpabet).replace(/[0-9]/g, "");
  }
  function getVowelProfileFromArpabet(arpabet) {
    let vowelProfile = "";
    const vowels = getVowels();
    console.log("arpabet");
    console.log(arpabet);
    arpabet.split(" ").forEach((arpPart) => {
      if (vowels.includes(arpPart.substring(0, 2))) {
        vowelProfile += arpPart;
      }
    });
    return vowelProfile;
  }
  globalThis.generateRhymeScore = generateRhymeScore;
  async function generateRhymeScore(params) {
    const prompt = params.prompt.toLowerCase();
    const inputValue = params.inputValue.toLowerCase();
    console.log("promptArpabetByPrompt");
    console.log(promptArpabetByPrompt);
    console.log(`inputValue: ${inputValue}, prompt: ${prompt}`);
    let inputArpabet = [];
    const promptArpabet = promptArpabetByPrompt[prompt];
    // for each word in input
    // get arpabet from db
    // const inputArpabetsArray = [];

    const inputWordArray = input.split(" ");
    // inputWordArray.forEach(async (inputWord) => {
    for (const inputWord of inputWordArray) {
      // console.log(inputWord);
      const inputArpabetJson = await getArpabetFromDb(
        inputWord.toLowerCase()
      ).catch((error) => {
        `could not find word: ${inputWord.toLowerCase()}`;
      });

      if (!!inputArpabetJson) {
        // inputArpabetJson
        // inputArpabetsArray.push(JSON.parse(inputArpabet[0])[0]);
        inputArpabet.push(JSON.parse(inputArpabetJson));
      }
    }
    console.log("inputArpabet");
    console.log(inputArpabet);
    // if (inputArpabet.length === 0) {
    //   return console.log("No input arpabet!");
    // }
    let arpabetSyllableCounts = {};
    // replace(/[0-9]/g, "")
    promptArpabet.forEach((promptPronunciation) => {
      promptPronunciation.split(" ").forEach((promptArpabetSyllable) => {
        if (!arpabetSyllableCounts[promptArpabetSyllable]) {
          arpabetSyllableCounts[promptArpabetSyllable] = 0;
        }
        arpabetSyllableCounts[promptArpabetSyllable] = 1;
      });
    });
    inputArpabet.forEach((inputArpabetWord) => {
      inputArpabetWord.forEach((inputWordPronunciation) => {
        inputWordPronunciation.split(" ").forEach((inputArpabetSyllable) => {
          if (!arpabetSyllableCounts[inputArpabetSyllable]) {
            arpabetSyllableCounts[inputArpabetSyllable] = 0;
          }
          arpabetSyllableCounts[inputArpabetSyllable]++;
        });
      });
    });

    console.log("arpabetSyllableCounts");
    console.log(arpabetSyllableCounts);
    let rhymeScore = 0;
    // make sure last syllable rhymes
    function doesLastSyllableRhyme(params) {
      console.log("params.inputArpabet");
      console.log(params.inputArpabet);
      if (!params.inputArpabet) {
        console.log("No input arp");
        // console.log("No input arp");
        return false;
      }
      if (!params.inputArpabet[0]) {
        return false;
      }
      if (!params.inputArpabet[0][0]) {
        return false;
      }
      const inputArpabet = params.inputArpabet[0][0];
      const promptArpabet = params.promptArpabet[0][0];
      console.log("inputArpabet");
      console.log(inputArpabet);
      console.log("promptArpabet");
      console.log(promptArpabet);
      const inputArpabetVowelProfile =
        getVowelProfileFromArpabetNoStress(inputArpabet);
      const promptArpabetVowelProfile =
        getVowelProfileFromArpabetNoStress(promptArpabet);
      console.log(`${inputArpabetVowelProfile} ${promptArpabetVowelProfile}`);
      return true;
    }

    const lastSyllableRhymes = doesLastSyllableRhyme({
      inputArpabet,
      promptArpabet,
    });

    if (lastSyllableRhymes) {
      Object.keys(arpabetSyllableCounts).forEach((arpabetSyllable) => {
        const count = arpabetSyllableCounts[arpabetSyllable];

        if (count > 1) {
          console.log(globalThis.score);
          rhymeScore += 10;
        }
      });
    }

    setTimeout(() => {
      replayScoreEffectAnimation(rhymeScore);
    }, 500);

    if (rhymeScore > 0) {
      globalThis.score += rhymeScore;
      setTimeout(setScoreText, 1000);

      await set(
        ref(db, `/my-score/${globalThis.myUsername}`),
        -1 * globalThis.score
      ).catch((error) => console.error(error));
    }
    // loop through inputArpabet and promptArpabet and create each complete case of input arpabet vowels with their occurences
    //  just loop thoruhg the arp and give points for commonality!
    // {AY: 1, EY:2, EH: 4} give points for count > 1
    console.log(
      `inputArpabet: ${inputArpabet}, promptArpabet: ${promptArpabet}`
    );
    // const promptArpabet
  }
  // async function generateRhymeScore(params) {
  //   // const input = getInputValue();
  //   // console.log("input");
  //   // console.log(params.input);
  //   if (params.input === params.prompt) {
  //     return console.log(`No points for same word!`);
  //   }
  //   let score = 0;
  //   // if(globalThis.targetWord)
  //   console.log("globalThis.targetWord");
  //   console.log(globalThis.targetWord);
  //   if (!globalThis.targetWord) {
  //     return console.log("wtf where is the target word from db?!");
  //   }
  //   // console.log(globalThis.n);
  //   console.log("globalThis.currentTargetWordIndex");
  //   console.log(globalThis.currentTargetWordIndex);
  //   const promptArpabetsJson =
  //     globalThis.targetWordsArpabets[globalThis.currentTargetWordIndex];
  //   const promptArpabets = JSON.parse(promptArpabetsJson);
  //   if (!promptArpabets) {
  //     return console.error("No Prompt Arpabet!");
  //   }

  //   log("targetPromptArpabets");
  //   log(promptArpabets);

  //   // for each word in input
  //   // get arpabet from db
  //   // const inputArpabetsArray = [];
  //   let inputArpabet = "";
  //   const inputWordArray = input.split(" ");
  //   // inputWordArray.forEach(async (inputWord) => {
  //   for (const inputWord of inputWordArray) {
  //     // console.log(inputWord);
  //     const inputArpabetJson = await getArpabetFromDb(inputWord);

  //     if (!!inputArpabetJson) {
  //       // inputArpabetJson
  //       // inputArpabetsArray.push(JSON.parse(inputArpabet[0])[0]);
  //       inputArpabet += `${JSON.parse(inputArpabetJson)[0]} `;
  //     }
  //     console.log("inputArpabet");
  //     console.log(JSON.parse(inputArpabetJson));
  //     // });

  //     if (inputArpabet.length > 0) {
  //       console.log("inputArpabet");
  //       console.log(inputArpabet);
  //       console.log("promptArpabets");
  //       console.log(promptArpabets);
  //       promptArpabets.forEach((promptArpabet) => {
  //         // Points for the same first letter
  //         if (promptArpabet[0] === inputArpabet[0]) {
  //           console.log(`Points for same first letter`);
  //           score += 10;
  //         }
  //         // Points for the same last letter
  //         if (
  //           promptArpabet[promptArpabet.length - 1] ===
  //           inputArpabet[inputArpabet.length - 1]
  //         ) {
  //           console.log(
  //             `Points for same last letter ${promptArpabet[promptArpabet - 1]}`
  //           );
  //           score += 10;
  //         }
  //         // Points for the same exact vowels
  //         const inputVowelProfile = getVowelProfileFromArpabet(inputArpabet);
  //         const promptVowelProfile = getVowelProfileFromArpabet(promptArpabet);
  //         console.log(
  //           `inputVowelProfile ${inputVowelProfile} promptVowelProfile ${promptVowelProfile}`
  //         );
  //         if (inputVowelProfile === promptVowelProfile) {
  //           console.log(`Points for PERFECT perfect rhyme!`);
  //           score += 100;
  //         }
  //         // Points for the same vowels without stress
  //         const inputVowelProfileNoStress =
  //           getVowelProfileFromArpabetNoStress(inputArpabet);
  //         const promptVowelProfileNoStress =
  //           getVowelProfileFromArpabetNoStress(promptArpabet);
  //         if (inputVowelProfileNoStress === promptVowelProfileNoStress) {
  //           console.log(`Points for rhyme without stress`);
  //           score += 50;
  //         }
  //         // Points for the same last vowel with stress

  //         const inputArpabetArray = getArpabetArrayFromArpabet(inputArpabet);
  //         const promptArpabetArray = getArpabetArrayFromArpabet(promptArpabet);
  //         console.log("inputArpabetArray");
  //         console.log(promptArpabetArray);
  //         if (
  //           inputArpabetArray[inputArpabetArray.length - 1] ===
  //           promptArpabetArray[promptArpabetArray.length - 1]
  //         ) {
  //           console.log(`Points for last syllable rhyme with stress`);

  //           score += 25;
  //         }

  //         // Points for the same last vowel without stress
  //         const inputArpabetArrayNoStress =
  //           getArpabetArrayFromArpabetNoStress(inputArpabet);
  //         const promptArpabetArrayNoStress =
  //           getArpabetArrayFromArpabetNoStress(promptArpabet);
  //         if (
  //           inputArpabetArrayNoStress[inputArpabetArrayNoStress.length - 1] ===
  //           promptArpabetArrayNoStress[promptArpabetArrayNoStress.length - 1]
  //         ) {
  //           console.log(`Points for last syllable rhyme with no stress`);
  //           score += 10;
  //         }
  //       });
  //     }
  //     globalThis.score += score;
  //     setScoreText();
  //     return score;
  //   }
  //   // get arpabet for each word
  // }

  /**
   * Animate
   */
  const clock = new THREE.Clock();
  let textSpinDirection = 1;
  globalThis.ticksPerSecond = 0;
  globalThis.ticksSinceLastSecond = 0;
  function setTicksPerSecond() {
    globalThis.ticksPerSecond =
      (globalThis.ticksSinceLastSecond + globalThis.ticksPerSecond) / 2;
    globalThis.ticksSinceLastSecond = 0;
  }
  setInterval(setTicksPerSecond, 1000);

  function fadeInSpaceBg() {
    if (globalThis.roundState === "PLAYING") {
      // globalThis.spaceBg.material.opacity < 0.5
      if (!!globalThis.spaceBg) {
        globalThis.spaceBg.material.opacity =
          globalThis.percentageToNextRound / 3;
      }
      // const spaceBgTransitionSpeed = (1 / 1000000) * globalThis.ticksPerSecond;
      // globalThis.spaceBg.material.opacity += spaceBgTransitionSpeed;
    }
  }

  function setTimeToNextRound() {
    globalThis.timeToNextRound = globalThis.roundEndTime - Date.now();
    // console.log("globalThis.timeToNextRound");
    // console.log(globalThis.timeToNextRound);

    const timeToNextRound = globalThis.timeToNextRound;
    let roundTime = 120;
    if (globalThis.roundState === "LOBBY") {
      roundTime = 60;
    }
    globalThis.percentageToNextRound = 1 - timeToNextRound / (roundTime * 1000);
    // console.log("globalThis.percentageToNextRound");
    // console.log(globalThis.percentageToNextRound);
  }

  const tick = () => {
    if (globalThis.isLoaded === false) {
      checkIfLoaded();
    }
    // setXpBar();
    globalThis.ticksSinceLastSecond++;
    const elapsedTime = clock.getElapsedTime();
    fadeInSpaceBg();
    rotateSpaceBg(elapsedTime);
    setTimeToNextRound();
    // globalThis.cloud.rotation.x = elapsedTime;
    uniforms.iResolution.value.set(canvas.width, canvas.height, 1);
    uniforms.iTime.value = clock.getElapsedTime();
    // const delta = clock.getDelta();
    // console.log("delta");
    // console.log("globalThis.ticksPerSecond");
    // console.log(globalThis.ticksPerSecond);
    globalThis.textSpeed = 5 / globalThis.ticksPerSecond;
    // console.log("globalThis.textSpeed");
    // console.log(textSpeed);
    // console.log(`${globalThis.timeToNextRound}/ 120000`);
    // console.log(`${globalThis.timeToNextRound}/ 120000`);
    // setXpBar(100 - globalThis.timeToNextRound / 1200);
    // float the ship
    let shipYFloatFactor = 0.25;
    let shipZRotationFactor = 0.1;
    if (globalThis.ship) {
      globalThis.ship.position.y =
        Math.sin(elapsedTime * shipYFloatFactor) / 4 - 1;
      globalThis.ship.rotation.z =
        Math.sin(elapsedTime * shipZRotationFactor) / 4 - 0.5;
      globalThis.ship.rotation.x = Math.sin(elapsedTime) / 100;
    }
    if (!!globalThis.ship) {
      light_4.position.set(
        ship.position.x,
        ship.position.y + 1,
        ship.position.z + 3
      );
      light_4.lookAt(ship.position.x, ship.position.y, ship.position.z);
    }
    // Math.pi / 41

    // console.log("globalThis.ship.rotation.z");
    // console.log(globalThis.ship.rotation.z);
    // setButtonTime(globalThis.timeToNextRound);
    // showInputField();
    // Make the world go
    if (!!globalThis.earth) {
      if (globalThis.earth.material.opacity < 1) {
        globalThis.earth.material.opacity += 0.1;
        // console.log("12332");
      }
      //   globalThis.earthDirection = 1;
      //   // globalThis.earth.position.y += Math.random() / 100;
      // }
      // globalThis.earthDirection = 1;
      // console.log(globalThis.roundState);

      const earthRoataionXSpeed = 0.0005;
      const earthRoataionZSpeed = 0.0001;
      if (globalThis.roundState != "LOBBY") {
        globalThis.earth.position.z += 0.01;
        globalThis.earth.position.y -= 0.01;
        globalThis.earth.rotation.x += earthRoataionXSpeed * 2;
        globalThis.earth.rotation.z += earthRoataionZSpeed;

        attachAtmosphere();
      } else {
        attachAtmosphere();
        spinStarsUp();
        globalThis.earth.position.y = -10;
        globalThis.earth.position.z = 0;
        globalThis.earth.rotation.x += earthRoataionXSpeed;
        globalThis.earth.rotation.z += earthRoataionZSpeed;
      }
      // if (globalThis.earth.position.z < -11) {
      //   globalThis.earth.visible = false;
      //   globalThis.earth.position.z = 8;
      //   earth.position.z = 4;
      //   earth.position.x = getRandomInt(-4, 4);
      //   // disposeEarthModel(globalThis.earth);
      // }
      // globalThis.earth.position.y -= Math.random() / 100;
    }
    // Check for collision
    // checkTextCollision();
    // // Begin Animate Attack Text
    // const attackTextSpeed = 1000 / globalThis.ticksPerSecond;
    // if (globalThis.attackTexts) {
    //   if (globalThis.attackTexts.length > 0) {
    //     for (const attackText of globalThis.attackTexts) {
    //       globalThis.attackTextZ = attackText.position.z;
    //       attackText.position.z -= attackTextSpeed;
    //       if (attackText.position.z < TEXT_START_Z) {
    //         // console.log("Disposing From Distance");
    //         disposeAttackText(attackText);
    //       }
    //     }
    //   }
    // }
    // End Animate Attack Text
    // Begin Animate Text
    // if (globalThis.text) {
    //   // text.material.opacity = 1;
    //   // light_4.position.z = globalThis.text.position.z;
    //   // light_4.intensity = 1;
    //   // console.log(light_4);
    //   if (globalThis.roundState === "LOBBY") {
    //     globalThis.currentTargetWordIndex = 0;
    //     hideText();
    //   } else {
    //     fadeInText();

    //     // Compensate for slower fps & ticks on mobile
    //     // let textSpeed = 50 / globalThis.sizes.width;
    //     const textSpeed = globalThis.textSpeed;
    //     // if (globalThis.sizes.width < 600) {
    //     //   // textSpeed *= 1.15;
    //     // }
    //     // console.log("textSpeed");
    //     // console.log(textSpeed);
    //     let textSpinSpeed = 0.001 * Math.random();
    //     text.position.z += textSpeed;
    //     text.rotation.z += textSpinSpeed * textSpinDirection;
    //     text.rotation.x += textSpinSpeed * textSpinDirection;
    //     globalThis.textZ = text.position.z;
    //   }
    //   if (text.position.z > 10) {
    //     resetText("end");
    //   } else {
    //     fadeInText();
    //   }
    // }
    // function hideText() {
    //   if (text.material.opacity > 0) {
    //     // console.log(text.material.opacity);
    //     text.material.opacity = 0;
    //     text.position.z = TEXT_START_Z;
    //     text.rotation.x = 0;
    //     text.rotation.y = 0;
    //     text.rotation.z = 0;
    //     globalThis.currentTargetWordIndex++;
    //     text.material.needsUpdate = true;

    //     // console.log("currentTargetWordIndex");
    //     // console.log(globalThis.currentTargetWordIndex);
    //   }
    // }
    // function fadeInText() {
    //   // setText();
    //   if (text.material.opacity === 0 && globalThis.roundState === "PLAYING") {
    //     // console.log("FADE IN");
    //     let textFadeIn = 0.005;
    //     text.material.opacity = 1;
    //     text.material.needsUpdate = true;
    //   }
    // }
    // function resetText(reason) {
    //   // console.log("resetText " + reason);
    //   // console.log("globalThis.targetWords");
    //   // console.log(globalThis.targetWords[globalThis.currentTargetWordIndex]);
    //   text.position.z = TEXT_START_Z;
    //   textSpinDirection *= -1;
    //   text.material.opacity = 1;
    //   text.rotation.x = TEXT_START_Z;
    //   setText(globalThis.targetWords[globalThis.currentTargetWordIndex]);
    //   globalThis.currentTargetWordIndex++;
    //   if (
    //     globalThis.currentTargetWordIndex >
    //     globalThis.targetWords.length - 1
    //   ) {
    //     globalThis.currentTargetWordIndex = 0;
    //   }
    //   // console.log("currentTargetWordIndex");
    //   // console.log(currentTargetWordIndex);
    //   // show the next text geometry here
    // }

    // End Animate Text
    // Begin  Animate Stars
    // let starSpinSpeed = 0.1;
    // let starSpeed = 0.001;
    // if (globalThis.roundState != "LOBBY") {
    //   starSpeed = 0.01;
    // } else {
    //   starSpeed = 0.001;
    // }
    // if (globalThis.roundState === "LOBBY") {
    //   particles.rotation.z = Math.PI / 4;
    // } else {
    //   particles.rotation.z = 0;
    // }
    // // particles.rotation.y = starSpinSpeed * elapsedTime;

    // for (let i = 0; i < count; i += 1) {
    //   let x = i;
    //   let y = i + 1;
    //   let z = i + 2;
    //   z = z * 3;
    //   x = x * 3;
    //   y = y * 3;

    //   const xPos = particlesGeometry.attributes.position.array[x];
    //   const yPos = particlesGeometry.attributes.position.array[y];
    //   const zPos = particlesGeometry.attributes.position.array[z];
    //   // console.log(`(${xPos}, ${yPos}, ${zPos})`);
    //   if (zPos > 10) {
    //     // console.log("Reset star");
    //     particlesGeometry.attributes.position.array[x] =
    //       (Math.random() - 1.5) * 5;
    //     particlesGeometry.attributes.position.array[y] =
    //       (Math.random() - 1.5) * 5;

    //     particlesGeometry.attributes.position.array[z] = -10;
    //   } else {
    //     particlesGeometry.attributes.position.array[z] += 1 * starSpeed;
    //     particlesGeometry.attributes.position.array[x] += 1 * starSpeed;
    //     particlesGeometry.attributes.position.array[y] += 1 * starSpeed;
    //     // console.log(particlesGeometry.attributes);
    //   }
    // }

    // particlesGeometry.attributes.position.needsUpdate = true;

    // Old Animate Stars
    let starSpinSpeed = 0.001;
    let starSpeed = 0.001;
    if (!!globalThis.roundState) {
      if (globalThis.roundState != "LOBBY") {
        starsGroup.rotation.z = elapsedTime * starSpinSpeed;
        starSpeed = 0.001;
        // starSpinSpeed = 0.01;
      }
    }
    function spinStarsUp() {
      // starsGroup.rotation.x = elapsedTime * 0.01;
      // starSpinSpeed = 0.01;
      for (var i = stars.length; i--; ) {
        const star = stars[i];
        star.position.y = star.position.y + 0.001;
        if (star.position.y > 10) {
          star.position.y = -10;
          // star.position.z = -50;
          star.position.x =
            ((Math.random() - 0.5) * globalThis.sizes.width) / 16;
          star.position.z =
            (Math.random() * 0.5 * globalThis.sizes.width) / 16 - 20;
          //   } else {
        }
      }
    }
    for (var i = stars.length; i--; ) {
      const star = stars[i];
      if (globalThis.roundState != "LOBBY") {
        star.position.z = star.position.z + starSpeed;
        if (star.position.z > 10) {
          star.position.z =
            ((Math.random() - 0.5) * globalThis.sizes.width) / 16;
          // star.position.z = -50;
          star.position.x =
            ((Math.random() - 0.5) * globalThis.sizes.width) / 16;
          star.position.y =
            ((Math.random() - 0.5) * globalThis.sizes.width) / 16;
          //   } else {
        }
      } else {
        if (star.position.z > 6) {
          star.position.z = Math.random() * 0.01;
        }
      }
      let starFadeIn = 0.01;
      if (star.material.opacity < 1) {
        //   star.material.opacity = 1;
      }
    }
    // End  Animate Stars
    // Update controls
    // controls.update();
    // Render

    renderer.render(scene, camera);

    //   // Call tick again on the next frame
    window.requestAnimationFrame(tick);
  };
  // //
  tick();
}
main();

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
